import React, { useEffect, useRef, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FaBars, FaChevronDown, FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";
import GoaNavbarLogo from "../../svg/GoaNavbarLogo";
import IconLink from "../../IconLink";

import * as styles from "./Navbar.module.scss";

export default function Navbar({ location }) {
  const { sanityCompanyInfo: companyInfo } = useStaticQuery(graphql`
    query NavbarQuery {
      sanityCompanyInfo {
        socialMediaLinks {
          facebook
          instagram
        }
      }
    }
  `);

  const [showMenu, setShowMenu] = useState(false);
  const navRef = useRef({ location: null });

  // Closes mobile nav menu on route change
  // Got help from: https://stackoverflow.com/questions/61274365/allow-component-to-detect-route-change-in-gatsby
  useEffect(() => {
    if (!navRef.current.location) {
      navRef.current.location = location;
    } else if (navRef.current.location !== location) {
      if (showMenu) {
        setShowMenu(false);
      }
      navRef.current.location = location;
    }
  }, [location, showMenu]);

  // Menu toggler function
  const toggleMobileMenu = () => {
    setShowMenu((prevShowMenu) => !prevShowMenu);
  };

  const menuClasses = showMenu ? styles.showMenu : "";

  return (
    <nav className={styles.navbar}>
      <div className="site-container">
        <Link to="/">
          <GoaNavbarLogo height={60} />
        </Link>

        <button
          id={styles.menuButton}
          className={showMenu ? styles.light : ""}
          onClick={toggleMobileMenu}
        >
          <FaBars />
        </button>

        <div className={`${styles.linksContainer} ${menuClasses}`} ref={navRef}>
          <ul>
            <li>
              <Link to="/" activeClassName={styles.activeLink}>
                About Us
              </Link>
            </li>
            <li>
              <Link to="/what-we-do/" activeClassName={styles.activeLink}>
                What We Do
              </Link>
            </li>
            <li>
              <Link to="/clubs/" activeClassName={styles.activeLink}>
                Clubs
              </Link>
            </li>
            <li>
              <Link to="/all-affiliates/" activeClassName={styles.activeLink}>
                Affiliates
              </Link>
            </li>
            <li>
              <Link to="/iddt" activeClassName={styles.activeLink}>
                IDDT
              </Link>
            </li>
            <li>
              <Link to="/machinery/" activeClassName={styles.activeLink}>
                Machinery
              </Link>
            </li>
            <li>
              <Link to="/faq/" activeClassName={styles.activeLink}>
                FAQ
              </Link>
            </li>
            <li>
              <Link to="/contact/" activeClassName={styles.activeLink}>
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/media/" activeClassName={styles.activeLink}>
                Media
              </Link>
            </li>
            <li>
              <Link to="/resources/" activeClassName={styles.activeLink}>
                Resources
              </Link>
            </li>
            <li>
              <Link to="/merchandise" activeClassName={styles.activeLink}>
                Merchandise
              </Link>
            </li>
          </ul>

          <div className={styles.dropdownContainer}>
            <span className={styles.loginBtn}>
              <GoaNavbarLogo height={20} /> Log in <FaChevronDown />
            </span>
            <div className={styles.dropdownContent}>
              {/* <a href="https://goa.atmos.co.za/Home.aspx">Atmos</a> */}
              <a href="https://growoneafrica.club/">Portal</a>
              <a href="https://lite.growoneafrica.club">GOALite</a>
            </div>
          </div>
          {/* <a href="https://goa.atmos.co.za/Login.aspx" className={styles.loginBtn}>
            <GoaNavbarLogo height={20} /> Log in <FaChevronDown />
          </a> */}
          <div className={styles.socialIcons}>
            <IconLink
              Icon={FaFacebook}
              title="Facebook"
              href={companyInfo.socialMediaLinks.facebook}
              target="_blank"
              rel="noreferrer"
            />
            <IconLink
              Icon={FaInstagram}
              title="Instagram"
              href={companyInfo.socialMediaLinks.instagram}
              target="_blank"
              rel="noreferrer"
            />
            <IconLink
              Icon={FaTiktok}
              title="TikTok"
              href="https://www.tiktok.com/@growoneafrica"
              target="_blank"
              rel="noreferrer"
            />
          </div>
        </div>
      </div>
    </nav>
  );
}
